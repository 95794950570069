<template>
  <div class="cont">
    <router-link to="/company-profile/marketing" class="back">
      <Icon type="ios-arrow-back" />
      <span>Go back to Marketing</span>
    </router-link>

    <div class="box">
      <Modal
        v-model="modal"
        title="Upload File"
        footer-hide
        :mask-closable="false"
      >
        <Alert show-icon>
          We recommend that you upload a photo with a minimum dimension of 400 x
          400.
        </Alert>

        <Cropper
          :stencil-props="{
            aspectRatio: 16 / 9
          }"
          classname="cropper"
          :src="image"
          ref="cropper"
        />

        <Button class="mr-20" @click="$refs.file.click()">
          <input
            v-show="false"
            type="file"
            ref="file"
            @change="uploadImage($event)"
            accept="image/*"
          />
          Choose image
        </Button>

        <Button
          :loading="uplodLoadingImage"
          :disabled="!image"
          @click="crop"
          type="primary"
        >
          Upload
        </Button>
      </Modal>

      <Form ref="create" :model="create" :rules="createRules">
        <FormItem label="COVER PHOTO" prop="cover" :rules="required">
          <div v-if="!create.cover" class="upload" @click="modal = true">
            <div class="upload-inner">
              <p>Click here to upload</p>
            </div>
          </div>

          <div class="upload" v-else>
            <Alert type="success">File upload successful</Alert>
            <img alt="" :src="create.cover" style="width:100px;" />
            <div>
              <Button @click="create.cover = undefined">Choose Again</Button>
            </div>
          </div>
        </FormItem>

        <FormItem label="CONTENT TITLE" prop="title">
          <Input v-model="create.title" />
        </FormItem>

        <FormItem label="DESCRIPTION" prop="description">
          <Input type="textarea" :rows="4" v-model="create.description" />
        </FormItem>

        <FormItem label="FILE UPLOAD" prop="file">
          <div
            v-if="!(create.file || file)"
            class="upload"
            @click="$refs.xxx.click()"
          >
            <div class="upload-inner">
              <input
                @change="uploadFile"
                v-show="false"
                type="file"
                ref="xxx"
              />
              <p>Click here to upload</p>
            </div>
          </div>
          <div class="upload" v-else>
            <div v-if="uplodLoadingFile">
              Uploading...
            </div>
            <div v-else>
              <div v-if="create.file">
                <Alert type="success">File upload successful</Alert>
                <a :href="create.file">file link</a>
                <div>
                  <Button @click="removeFile">Choose Again</Button>
                </div>
              </div>
            </div>
          </div>
        </FormItem>

        <FormItem>
          <Button
            style="float:right;"
            :loading="createLoading"
            type="primary"
            @click="createSubmit"
            >Submit Content</Button
          >
        </FormItem>
      </Form>
    </div>
  </div>
</template>

<script>
import api from "@/api";
const { _createMarketing } = api;
import S3 from "aws-s3";
import { Cropper } from "vue-advanced-cropper";

const uploader = new S3({
  bucketName: "connexima",
  dirName: "documents",
  region: "us-west-1",
  accessKeyId: "AKIA6KR6UZLLVZYGDEZM",
  secretAccessKey: "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"
});

export default {
  components: {
    Cropper
  },
  created() {},
  data() {
    return {
      modal: false,
      image: null,
      uplodLoadingImage: false,
      uplodLoadingFile: false,
      file: null,

      create: {
        title: "",
        description: "",
        cover: "",
        file: ""
      },
      createLoading: false,

      required: [
        {
          required: true,
          message: "Please fill"
        }
      ],

      createRules: {
        title: [
          {
            required: true
          }
        ],
        description: [
          {
            required: true
          }
        ],
        cover: [
          {
            required: true
          }
        ],
        file: [
          {
            required: true
            // validator: (rule, value, callback) => {
            //   if (this.file) {
            //     callback();
            //   } else {
            //     callback(new Error("file is required"));
            //   }
            // }
          }
        ]
      }
    };
  },

  methods: {
    uploadImage(event) {
      var input = event.target;
      if (input.files && input.files[0]) {
        var reader = new FileReader();
        reader.onload = e => {
          this.image = e.target.result;
        };
        reader.readAsDataURL(input.files[0]);
      }
    },

    crop() {
      const { canvas } = this.$refs.cropper.getResult();
      canvas.toBlob(blob => {
        this.uplodLoadingImage = true;
        uploader
          .uploadFile(blob)
          .then(data => {
            this.modal = false;
            this.create.cover = data.location;
          })
          .catch(() => {
            this.$Message.error("upload error");
          })
          .finally(() => {
            this.uplodLoadingImage = false;
          });
      });
    },

    uploadFile(e) {
      const file = e.target.files[0];
      if (file) {
        if (file.size > 20 * 1024 * 1024) {
          this.$Message.error("File size cannot exceed 20MB");
        } else {
          this.file = file;
          this.uplodLoadingFile = true;
          uploader
            .uploadFile(file)
            .then(data => {
              this.create.file = data.location;
            })
            .catch(() => {
              this.$Message.error("upload error");
            })
            .finally(() => {
              this.uplodLoadingFile = false;
            });
        }
      }
    },

    removeFile() {
      this.file = null;
      this.create.file = "";
    },

    createSubmit() {
      this.$refs.create.validate(valid => {
        if (valid) {
          this.createLoading = true;
          _createMarketing({
            title: this.create.title,
            description: this.create.description,
            cover: this.create.cover,
            file: this.create.file
          })
            .then(() => {
              this.$Message.success("success");
              this.$router.push("/company-profile/marketing");
            })
            .catch(({ message }) => {
              this.$Message.error(message);
            })
            .finally(() => {
              this.createLoading = false;
            });
        }
      });
    }
  }
};
</script>

<style lang="less" scoped>
.back {
  color: @primary-color;
  display: flex;
  align-items: center;
  font-size: 14px;
  padding: 14px 24px;
  border-bottom: 1px solid #ccc;
  font-weight: bold;
  background: #fff;
}
.cont {
  background: #fff;
  .box {
    margin: 0 auto;
    padding: 20px;
    width: 600px;
    .upload {
      width: 100%;
      display: inline-block;
      border: 1px solid #ccc;
      border-radius: 3px;
      padding: 20px;
      cursor: pointer;

      .upload-inner {
        height: 200px;
        display: flex;
        align-items: center;
        justify-content: center;
        flex-direction: column;
        .icon {
          color: @primary-color;
        }
      }
    }
  }
}
.cropper {
  width: 100%;
  height: 300px;
  border: solid 1px #ccc;
  margin-bottom: 20px;
}
</style>
